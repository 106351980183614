import React from 'react'

import { useTranslation } from 'react-i18next';

const WhoWeAre = () => {
  const { t } = useTranslation();
  return (
    <section className="section section-lg section-last text-center">
      <div className="container">
      <h3>{t('whoWeAre.sectionHeading')}</h3>
        <div className="row row-50">
          <div className="col-sm-6 col-lg-4">
            <article className="profile-minimal">
              <img
                className="profile-minimal-image"
                src="/images/team-2-370x368.jpg"
                alt=""
                width="370"
                height="368"
              />
              <div className="profile-minimal-caption">
                <h4 className="profile-minimal-title">Yenisey Corrales</h4>
                <p className="profile-minimal-position">CEO, Founder</p>
              </div>
            </article>
          </div>
          <div className="col-sm-6 col-lg-4">
            <article className="profile-minimal">
              <img
                className="profile-minimal-image"
                src="/images/team-3-370x368.jpg"
                alt=""
                width="370"
                height="368"
              />
              <div className="profile-minimal-caption">
                <h4 className="profile-minimal-title">Brian Nelson</h4>
                <p className="profile-minimal-position">Executive Assistant</p>
              </div>
            </article>
          </div>
          <div className="col-sm-6 col-lg-4">
            <article className="profile-minimal">
              <img
                className="profile-minimal-image"
                src="/images/team-1-370x368.jpg"
                alt=""
                width="370"
                height="368"
              />
              <div className="profile-minimal-caption">
                <h4 className="profile-minimal-title">Nathan Porter</h4>
                <p className="profile-minimal-position">Audit and Tax Compliance Manager</p>
              </div>
            </article>
          </div>
          <div className="col-sm-6 col-lg-4">
            <article className="profile-minimal">
              <img
                className="profile-minimal-image"
                src="/images/team-4-370x368.jpg"
                alt=""
                width="370"
                height="368"
              />
              <div className="profile-minimal-caption">
                <h4 className="profile-minimal-title">Marie Fernandez</h4>
                <p className="profile-minimal-position">Tax Preparer/Manager</p>
              </div>
            </article>
          </div>
          <div className="col-sm-6 col-lg-4">
            <article className="profile-minimal">
              <img
                className="profile-minimal-image"
                src="/images/team-5-370x368.jpg"
                alt=""
                width="370"
                height="368"
              />
              <div className="profile-minimal-caption">
                <h4 className="profile-minimal-title">Sam Williams</h4>
                <p className="profile-minimal-position">Accounting Manager</p>
              </div>
            </article>
          </div>
          <div className="col-sm-6 col-lg-4">
            <article className="profile-minimal">
              <img
                className="profile-minimal-image"
                src="/images/team-6-370x368.jpg"
                alt=""
                width="370"
                height="368"
              />
              <div className="profile-minimal-caption">
                <h4 className="profile-minimal-title">Bruce Wilson</h4>
                <p className="profile-minimal-position">Notary Services Manager</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre
