import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'

const OverView = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-lg">
      <div className="container">
        <div className="row row-50 justify-content-center justify-content-lg-between">
          <div className="col-md-10 col-lg-6">
            <h3>{t('overview.heading')}</h3>
            <h4 className="offset-top-3">{t('overview.subHeading')}</h4>
            <p>{t('overview.paragraph')}</p>
            <div className="group group-middle">
              <Link
                className="button button-primary button-winona"
                to="/services"
                data-waypoint-to="#our-services"
                placeholder=""
              >
                {t('overview.seeOurServices')}
              </Link>
              <Link
                className="button button-primary-outline button-winona"
                to="/careers"
                placeholder=""
              >
                {t('overview.joinTeamBtn')}
              </Link>
            </div>
          </div>
          <div className="col-md-10 col-lg-6 col-xl-5">
            {' '}
            <img
              className="img-responsive"
              src="/images/large-features-1-470x334.png"
              alt=""
              width="470"
              height="334"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default OverView
