import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import { useTranslation } from 'react-i18next'

const Banner = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-xs bg-primary-darker text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-12">
            <div className="box-cta-thin">
              <h4 className="wow-outer">
                <span className="wow slideInRight">{t('ctaSection.heading')}</span>
              </h4>
              <div className="wow-outer button-outer">
                <Link
                  className="button button-primary button-winona wow slideInLeft"
                  to="/services#pricing"
                  placeholder=""
                >
                  {t('ctaSection.buttonText')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
