import React from 'react'
import Br from '@/Common/Breadcrumbs'
import OverView from '@/components/About/Overview'
import TimeLine from '@/components/About/TimeLine'
import WhoWeAre from '@/components/About/WhoWeAre'
import Banner from '@/components/About/Banner'
import { useTranslation } from 'react-i18next'

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <Br
        subTitle={t('aboutUsTitle')}
        title={t('aboutUsTitle')}
        routes={[
          { to: '/', val: t('home') },
          { to: '#', val: t('aboutUsTitle') },
        ]}
      />
      <OverView />
      {/* <TimeLine /> */}
      <WhoWeAre />
      <Banner />
    </>
  )
}

export default About
